<template>
    <form @submit.prevent="onSave" class="relative space-y-6 sm:space-y-5 h-full p-6 w-full">
        <header class="info-header space-y-1 py-2 px-4 bg-blue-700 rounded sticky">
            <div class="flex items-center">
                <!--                <button :class="['primary-button', saveDisabled && 'pointer-events-none cursor-default opacity-50']">-->
                <button class="primary-button">
                    <span v-if="saving" class="flex items-center">
                        <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                    </span>
                    <span v-else>Save</span>
                </button>
            </div>
        </header>

        <autralis-success-comp :show="updated" text="The information successfully updated."/>
        <autralis-error-comp :show="error !== ''" :text="error"/>
        <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>


        <div class="flex w-full space-x-6 mt-6">
            <div class="w-full border-0 md:border border-gray-200 rounded pb-10 md:pt-10 px-0 md:px-6 lg:px-16 xl:px-28" style="max-width: 1024px;">
                <div class="text-left mb-9">
                    <h2 class="text-2xl md:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900 ">
                        General info
                    </h2>
                    <p class="mt-1 text-base md:text-lg leading-6 text-gray-500">
                        Edit the basic information of your profile.
                    </p>
                </div>

                <div class="w-full">
                    <autralis-small-select-comp title="Title" :selectedValue="user.title" @on-change="user.title = $event" :values="userTitles"/>
                </div>


                <div class="flex flex-col xl:flex-row space-x-0 xl:space-x-6 mt-6">
                    <div class="w-full xl:w-1/2 mb-6 xl:mb-0">
                        <autralis-input-group-comp label="First name"
                                                   :error="formErrors.first_name"
                                                   placeholder="John"
                                                   required
                                                   @on-change="user.first_name = $event"
                                                   :value="user.first_name"/>
                    </div>
                    <div class="w-full xl:w-1/2">

                        <autralis-input-group-comp label="Last name"
                                                   :error="formErrors.last_name"
                                                   placeholder="Doe"
                                                   required
                                                   @on-change="user.last_name = $event"
                                                   :value="user.last_name"/>

                    </div>
                </div>


                <div class="mt-6">
                    <autralis-input-group-comp label="Email address"
                                               :error="formErrors.email"
                                               placeholder="john.doe@company.com"
                                               required
                                               @on-change="user.email = $event"
                                               :value="user.email"/>
                </div>

                <div class="flex w-full flex-row flex-col xl:flex-row space-x-0 xl:space-x-6 mt-6">
                    <div class="w-full xl:w-1/2 mb-6 xl:mb-0">
                        <autralis-input-group-comp label="Mobile"
                                                   :error="formErrors.mobile"
                                                   placeholder="+32 486 12 34 56"
                                                   @on-change="user.mobile = $event"
                                                   :value="user.mobile"/>
                    </div>
                    <div class="w-full xl:w-1/2">

                        <autralis-input-group-comp label="Telephone"
                                                   :error="formErrors.telephone"
                                                   placeholder="+32 9 230 02 33"
                                                   @on-change="user.telephone = $event"
                                                   :value="user.telephone"/>

                    </div>
                </div>


                <div class="flex flex-col mt-6">
                    <label class="block text-sm font-medium mb-1 text-gray-700">Sms notification</label>
                    <div class="mt-1">
                        <t-toggle v-model="user.smsActive"/>
                    </div>
                </div>

            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import SmallSelect from "@/components/select-small-boxes";
import InfoBox from "@/components/info-box";
import InputGroup from "@/components/input-group";
import {isValidEmail} from "../../../../../common";
import {isEqual} from "lodash";
import {INFO_BOX_TIMEOUT, USER_TITLES} from "../../../../constants";

export default {
    name: "InfoDashboard",
    data() {
        return {
            user: {},
            initUser: {},
            saving: false,
            error: '',
            updated: false,
            formErrors: {},
            noChangesMessage: null
        }
    },
    computed: {
        switchStyle() {
            let classes = "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200";
            classes = this.user.smsActive ? classes + " translate-x-5" : classes + " translate-x-0"
            return classes
        },

        hasChanges: {
            cache: false,
            get: function () {
                return !isEqual(this.user, this.initUser)
            }
        },
        checked() {
            return this.user.smsActive
        },
        userTitles() {
            return USER_TITLES;
        }
    },
    methods: {
        setUser() {
            const user = {
                title: this.$store.getters['master/user'].title ? this.$store.getters['master/user'].title : '',
                first_name: this.$store.getters['master/user'].first_name ? this.$store.getters['master/user'].first_name : '',
                last_name: this.$store.getters['master/user'].last_name ? this.$store.getters['master/user'].last_name : '',
                email: this.$store.getters['master/user'].email ? this.$store.getters['master/user'].email : '',
                telephone: this.$store.getters['master/user'].telephone ? this.$store.getters['master/user'].telephone : '',
                mobile: this.$store.getters['master/user'].mobile ? this.$store.getters['master/user'].mobile : '',
                smsActive: this.$store.getters['master/user'].smsActive ? this.$store.getters['master/user'].smsActive : false
            }
            this.user = {...user};
            this.initUser = {...user};
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.user.first_name) {
                errors['first_name'] = "The first name cannot be empty.";
                isValid = false;
            }

            if (!this.user.last_name) {
                errors['last_name'] = "The last name cannot be empty.";
                isValid = false;
            }

            if (!isValidEmail(this.user.email)) {
                errors['email'] = "The format of the email address is not correct.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async onSave() {
            if (this.isValid()) {
                if (this.hasChanges) {
                    this.saving = true;
                    this.updated = false;

                    this.error = '';
                    try {
                        const response = await axios.post('/manager/expert3/xp3/update-user-profile/', this.user, {withCredentials: true});
                        if (response.data.result === 'Ok') {
                            this.error = '';
                            this.updated = true;
                            this.$store.commit('master/setUser', this.user)
                            this.initUser = {...this.user}
                        } else {
                            this.error = response.data.message;
                        }

                        this.saving = false;
                        this.noChangesMessage = null;


                    } catch (e) {
                        this.error = 'Your information could not be updated.';
                        this.saving = false;
                    }
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }

            }
        }
    },

    created() {
        this.setUser();
    },
    components: {
        'autralis-error-comp': ErrorBox,
        'autralis-success-comp': SuccessBox,
        'autralis-info-comp': InfoBox,
        'autralis-small-select-comp': SmallSelect,
        'autralis-input-group-comp': InputGroup
    }
}
</script>

<style scoped>
.info-header {
    top: 0;
    z-index: 10
}

@media (max-width: 1023px) {
    .info-header {
        top: 157px;
    }
}

</style>